class initAccordions {
	constructor() {
		this.accActive = $('.wz-acc-item[data-active="true"]');
		this.items = $('.wz-acc-item');
	}
	 openAccItem = (item, index) => {

		console.log(index);

		let body = $('.wz-acc-item[data-acc-item="' + index + '"] .wz-acc-item__body');
		let content = $('.wz-acc-item[data-acc-item="' + index + '"] .wz-acc-item__body .wz-acc-item__body__content');

		if ($('.wz-acc-item[data-acc-item="' + index + '"] .wz-acc-item__title').hasClass('is-open')) {
			$('.wz-acc-item .wz-acc-item__body').attr('style', '');
			$('.wz-acc-item .wz-acc-item__title').removeClass('is-open');
		} else {
			$('.wz-acc-item .wz-acc-item__body').attr('style', '');
			body.css({
				height: content.outerHeight(true) + 'px'
			});
			$('.wz-acc-item .wz-acc-item__title').removeClass('is-open');
			$('.wz-acc-item[data-acc-item="' + index + '"] .wz-acc-item__title').addClass('is-open');
		}

	};
	 triggerAcc  = (name) => {
		var body = $('.wz-acc-item[data-acc-id="' + name + '"] .wz-acc-item__body');
		var content = $('.wz-acc-item[data-acc-id="' + name + '"] .wz-acc-item__body .wz-acc-item__body__content');

		if ($('.wz-acc-item[data-acc-id="' + name + '"] .wz-acc-item__title').hasClass('is-open')) {
			$('.wz-acc-item .wz-acc-item__body').attr('style', '');
			$('.wz-acc-item .wz-acc-item__title').removeClass('is-open');
		} else {
			$('.wz-acc-item .wz-acc-item__body').attr('style', '');
			body.css({
				height: content.outerHeight(true) + 'px'
			});
			$('.wz-acc-item .wz-acc-item__title').removeClass('is-open');
			$('.wz-acc-item[data-acc-id="' + name + '"] .wz-acc-item__title').addClass('is-open');
		}

	};
	 generateAccordions =() => {

		 let thisObj = this;

		 if (this.items.length > 0) {
			 for (let i = 0; i < this.items.length; i++) {
				 $(this.items[i]).attr('data-acc-item', i);
				 $(this.items[i]).find('wz-acc-item__title').attr('data-acc-item', i);

				 if ($(this.items[i]).attr('data-active') === 'true') {
					 this.openAccItem($(this.accActive[0]), $(this.accActive[0]).attr('data-index'));
				 }
			 }

			 $('body').on('click', '.wz-acc-item .wz-acc-item__title', function(e) {
				 e.preventDefault();
				 let itemIndex = $(this).parent().attr('data-acc-item');
				 if ($(this).parent().parent().attr('data-soon') !== 'true') {
					 thisObj.openAccItem($(this), itemIndex);
				 }
			 });
		 }
	 }

	init(){
		this.generateAccordions();
	};

}
$(function () {
	var cgInitAccordions = new initAccordions();
	window.cgInitAccordions = cgInitAccordions;
	cgInitAccordions.init();
});
